<template>
  <div>
    <p>🟧 🟧 🟧 🟧 -- รายการกล้อง เลนส์ ให้เช่า -- 🟧 🟧 🟧 🟧</p>
    <p>[UPDATE {{ currentMonthYear }}]</p>
    <p>
      ร้านให้เช่ากล้อง เช่าเลนส์ Mirrorless · DSLR · GoPro<br />
      ชั้น 12 อาคารเอเชีย ติด BTS ราชเทวี <br />
      www.lenslineup.com
    </p>

    <br />

    <div
      v-for="(cat, index) in categories"
      :key="index"
      v-if="
        categorizedProducts[cat.categories_name] &&
        categorizedProducts[cat.categories_name].length > 0
      "
    >
      <p>{{ toBoldSans(cat.categories_name) }}</p>
      <ul>
        <li
          v-for="(x, index) in categorizedProducts[cat.categories_name]"
          :key="index"
        >
          <div class="d-flex justify-content-between">
            <div>
              {{ x.web_name }} - {{ x.rental_price | number }}{{ unit_text }}
            </div>
          </div>
        </li>
      </ul>
      <br />
    </div>

    <p>
      ================================= <br />
      <br />
      ร้าน Lenslineupให้เช่ากล้อง เช่าเลนส์ Mirrorless · DSLR · GoPro <br />
      ชั้น 12 อาคารเอเชีย ติด BTS ราชเทวี <br />
      www.lenslineup.com โทร <br />
      02-611-0695 080-818-8872 080-818-8873 080-818-8874 <br />
      Line : lenslineup.com/LINE <br />
      📍 : lenslineup.com/MAP <br />

      #เช่ากล้อง #เช่าเลนส์ #ร้านเช่ากล้อง #ร้านเช่าเลนส์ #lenslineup
      #ให้เช่ากล้อง #ให้เช่าเลนส์ #ร้านให้เช่าเลนส์กรุงเทพ
      #ร้านให้เช่ากล้องกรุงเทพ
    </p>
  </div>
</template>

<script>
import product_categories from "@/assets/product_categories_pricelist.json";

export default {
  computed: {
    currentMonthYear() {
      const date = new Date();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two digits for month
      const year = String(date.getFullYear()).slice(-2); // Get last two digits of year
      return `${month}/${year}`;
    },
  },
  components: {},
  data() {
    return {
      price_data: [],
      unit_text: " ฿/วัน",
      categories: [],
      debug_data: [],
      categorizedProducts: [],
    };
  },
  created() {
    this.categories = product_categories;
    this.load_data();
  },
  methods: {
    load_data() {
      this.$http({
        method: "GET",
        url: "http://localhost:3080/user/web/price-list/",
      }).then(async (x) => {
        this.price_data = x.data.data.price_data;

        this.categorizedProducts = this.price_data.reduce((acc, item) => {
          // Check if the category already exists, if not, initialize it
          if (!acc[item.categories_name]) {
            acc[item.categories_name] = [];
          }
          // Append the products to the existing array
          acc[item.categories_name].push(...item.products);
          return acc;
        }, {});
      });
    },
    toBoldSans(text) {
      const boldSansMap = {
        A: "𝗔",
        B: "𝗕",
        C: "𝗖",
        D: "𝗗",
        E: "𝗘",
        F: "𝗙",
        G: "𝗚",
        H: "𝗛",
        I: "𝗜",
        J: "𝗝",
        K: "𝗞",
        L: "𝗟",
        M: "𝗠",
        N: "𝗡",
        O: "𝗢",
        P: "𝗣",
        Q: "𝗤",
        R: "𝗥",
        S: "𝗦",
        T: "𝗧",
        U: "𝗨",
        V: "𝗩",
        W: "𝗪",
        X: "𝗫",
        Y: "𝗬",
        Z: "𝗭",

        // Lowercase letters a-z
        a: "𝗮",
        b: "𝗯",
        c: "𝗰",
        d: "𝗱",
        e: "𝗲",
        f: "𝗳",
        g: "𝗴",
        h: "𝗵",
        i: "𝗶",
        j: "𝗷",
        k: "𝗸",
        l: "𝗹",
        m: "𝗺",
        n: "𝗻",
        o: "𝗼",
        p: "𝗽",
        q: "𝗾",
        r: "𝗿",
        s: "𝘀",
        t: "𝘁",
        u: "𝘂",
        v: "𝘃",
        w: "𝘄",
        x: "𝘅",
        y: "𝘆",
        z: "𝘇",

        // Numbers 0-9
        0: "𝟬",
        1: "𝟭",
        2: "𝟮",
        3: "𝟯",
        4: "𝟰",
        5: "𝟱",
        6: "𝟲",
        7: "𝟳",
        8: "𝟴",
        9: "𝟵",
      };
      return text
        .split("")
        .map((char) => boldSansMap[char] || char)
        .join("");
    },
  },
};
</script>

<style scoped>
* {
  all: revert; /* Resets all styles to browser default */
}
body,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
img,
span {
  font-family: initial !important; /* Resets fonts to default */
  text-decoration: none; /* Removes any text decorations */
  color: inherit; /* Uses inherited colors */
}
.bold-sans {
  font-size: 24px !important; /* Adjust size */
  font-weight: normal !important; /* Already bold via Unicode */
}
</style>
